import CRUD from './crud'
import axios from '../../utils/axios';

const tickets = new CRUD('tickets')

export default Object.assign(
    tickets,
    {
        reducers: {
            ...tickets.reducers
        },

        verify: (id_tournament, scope, id_participant, timestamp) => {
            return async (dispatch) => {
                dispatch(tickets.slice.actions.startLoading());
                try {
                    return axios
                        .get(`/tickets/${id_tournament}/${scope}/${id_participant}/${timestamp}`)
                        .catch(error => {
                            console.log('AXIOS error', error)
                        })
                } catch (error) {
                    console.error(error);
                }
            };
        },

        accept: (id) => {
            return async (dispatch) => {
                dispatch(tickets.slice.actions.startLoading());
                try {
                    return axios
                        .post(`/tickets/${id}/accept`)
                        .catch(error => {
                            console.log('AXIOS error', error)
                        })
                } catch (error) {
                    console.error(error);
                }
            };
        },

        decline: (id) => {
            return async (dispatch) => {
                dispatch(tickets.slice.actions.startLoading());
                try {
                    return axios
                        .delete(`/tickets/${id}/decline`)
                        .catch(error => {
                            console.log('AXIOS error', error)
                        })
                } catch (error) {
                    console.error(error);
                }
            };
        },
    }
)
