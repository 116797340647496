import { createSlice } from '@reduxjs/toolkit';
import axios from '../../utils/axios';

class CRUD{

    initialState = {
        isLoading: false,
        error: null,
        items: {data: []},
        allItems: [],
        item: null,
        saved: null,
        deleted: null
    };

    name = null

    slice = null

    constructor(name) {
        this.name = name
        this.createSlice()
    }

    getReducer(){
        return this.slice.reducer
    }

    createSlice(){
        this.slice = createSlice({
            name: this.name,
            initialState: this.initialState,
            reducers: {
                startLoading(state) {
                    state.isLoading = true;
                },

                hasError(state, action) {
                    state.isLoading = false;
                    state.error = action.payload;
                },

                getItemsSuccess(state, action) {
                    state.isLoading = false;
                    state.items = action.payload;
                },

                getAllItemsSuccess(state, action) {
                    state.isLoading = false;
                    state.allItems = action.payload;
                },

                getItemSuccess(state, action) {
                    state.isLoading = false;
                    state.item = action.payload;
                },

                saveItemSuccess(state, action) {
                    state.isLoading = false;
                    state.saved = action.payload
                },

                deleteItemSuccess(state, action) {
                    state.isLoading = false;
                    state.deleted = action.payload
                },
            },
        });
    }

    getItems(page=1, perPage=10, filters={}) {
        return async (dispatch) => {
            dispatch(this.slice.actions.startLoading());
            try {
                const response = await axios
                    .get(this.name, {
                        params: {
                            'page': page,
                            'limit': perPage,
                            'filters': filters
                        }
                    })
                    .catch(error => {
                        console.log('AXIOS error', error)
                    });
                dispatch(this.slice.actions.getItemsSuccess(response?.data));
            } catch (error) {
                dispatch(this.slice.actions.hasError(error));
            }
        };
    }

    getAllItems(filters={}) {
        return async (dispatch) => {
            dispatch(this.slice.actions.startLoading());
            try {
                const response = await axios
                    .get(this.name, {params: {'filters': filters}})
                    .catch(error => {
                        console.log('AXIOS error', error)
                    });
                return dispatch(this.slice.actions.getAllItemsSuccess(response?.data));
            } catch (error) {
                dispatch(this.slice.actions.hasError(error));
            }
        };
    }

    getItem(id) {
        return async (dispatch) => {
            dispatch(this.slice.actions.startLoading());
            try {
                const response = await axios
                    .get(`/${this.name}/${id}`)
                    .catch(error => {
                        console.log('AXIOS error', error)
                    });
                dispatch(this.slice.actions.getItemSuccess(response?.data));
            } catch (error) {
                console.error(error);
                dispatch(this.slice.actions.hasError(error));
            }
        };
    }


    deleteItem(id) {
        return async (dispatch) => {
            dispatch(this.slice.actions.startLoading());
            try {
                const response = await axios
                    .delete(`/${this.name}/${id}`)
                    .catch(error => {
                        console.log('AXIOS error', error)
                    })
                return dispatch(this.slice.actions.deleteItemSuccess(response?.data));
            } catch (error) {
                console.error(error);
                return dispatch(this.slice.actions.hasError(error));
            }
        };
    }

    saveItem(item) {
        return async (dispatch) => {
            dispatch(this.slice.actions.startLoading());
            try {
                const response = await axios
                    .post(this.name, item)
                    .catch(error => {
                        console.log('AXIOS error', error)
                    })
                return dispatch(this.slice.actions.saveItemSuccess(response?.data));
            } catch (error) {
                console.error(error);
                return dispatch(this.slice.actions.hasError(error));
            }
        };
    }
}


export default CRUD
